import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import styled from 'styled-components';
import { GlendaFunkSvg, IntegrationIconSvg, PicwizAvatarSvg } from 'assets/img';
import MainButton from 'components/MainButton';

const StyledCard = styled(Box)`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 20px;
  background: var(
    --Subtle-box-bg,
    radial-gradient(
      150.2% 50% at 50% 50%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.1) 100%
    )
  );

  box-shadow: -0.5px 0.5px 2px 0px rgba(144, 159, 148, 0.8) inset;
`;

const StyledButton = styled(Button)`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 100px;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2px;
  height: 40px;
`;

const CardBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CardDetailBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 4px;
`;

const AvatarBox = styled(PicwizAvatarSvg)`
  width: 48px;
  height: auto;
`;

const NameBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const NameLabel = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const UserBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const GlendaFunk = styled(GlendaFunkSvg)`
  width: 20px;
  height: auto;
`;

const UserLabel = styled(Typography)`
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const MyAchievedBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const IntegrationBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const IntegrationIcon = styled(IntegrationIconSvg)`
  width: 32px;
  height: auto;
`;

const IntegrationLabel = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const MyAchievedLabel = styled(Typography)`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: 16px;
`;

const ExploreDesc = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

interface ExploreCardProps {
  title: string;
  creator: string;
  description: string;
  status: string;
  achieved?: number;
  onClick?: () => void;
}

const ExploreCard: React.FC<ExploreCardProps> = ({
  title,
  creator,
  description,
  status,
  achieved,
  onClick,
}) => {
  const renderButton = () => {
    switch (status) {
      case 'continue':
        return (
          <StyledButton sx={{ background: '#111', color: '#fff' }}>
            Continue
          </StyledButton>
        );
      case 'join':
        return (
          <MainButton
            text="Continue"
            height="40px"
            borderRadius="20px"
            onClick={onClick}
          />
        );
      case 'coming-soon':
        return (
          <StyledButton sx={{ background: '#132828', color: '#fff' }} disabled>
            Coming Soon
          </StyledButton>
        );
      default:
        return null;
    }
  };

  return (
    <StyledCard>
      <CardBox>
        <CardDetailBox>
          <AvatarBox />
          <NameBox>
            <NameLabel>{title}</NameLabel>
            <UserBox>
              <GlendaFunk />
              <UserLabel>Created by {creator}</UserLabel>
            </UserBox>
          </NameBox>
        </CardDetailBox>
        {achieved !== undefined && (
          <MyAchievedBox>
            <IntegrationBox>
              <IntegrationIcon />
              <IntegrationLabel>{achieved}</IntegrationLabel>
            </IntegrationBox>
            <MyAchievedLabel>My achieved</MyAchievedLabel>
          </MyAchievedBox>
        )}
      </CardBox>

      <ExploreDesc>{description}</ExploreDesc>

      {renderButton()}
    </StyledCard>
  );
};

export default ExploreCard;
