import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  TextField,
  Button,
} from '@mui/material';
import styled, { keyframes } from 'styled-components';
import BottomDrawer from 'components/BottomDrawer';
import {
  SendIconSvg,
  ChatCreationIconSvg,
  ChatStyleIconSvg,
  ChatLibraryIconSvg,
  GuideIconSvg,
} from 'assets/img';
import { useHistory, useLocation } from 'react-router-dom';
import fileStore from 'stores/fileStore';
import { observer } from 'mobx-react';
import './chat.css';
import Back from 'components/Back';
import BottomNav from 'components/BottomNav';
import { motion, AnimatePresence } from 'framer-motion';
import HoneycombLoader from 'components/HoneycombLoader';
import { serverRequestApi } from 'gql';
import {
  StyleVo,
  GenerateImgReq,
  SystemTaskVo,
  FileVo,
} from 'gql/server/__generated__/request';
import api from 'request/api';
import chatStore from 'stores/chatStore';
import MainButton from 'components/MainButton';
import { showErrorToast, useToast } from 'components/ToastContext';
import UserPhotos from 'components/UserPhotos';
import ImageDialog from 'components/ImageDialog';

const CustomStepIcon = styled('div')<{ active?: boolean; completed?: boolean }>`
  width: 40px;
  height: 28px;
  border-radius: 20px;
  border: ${({ active }) =>
    active ? '2px solid #55EB64' : '1px solid rgba(255, 255, 255, 0.12)'};
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? '#53EB8D' : 'rgba(255, 255, 255, 0.60)')};
  font-weight: bold;
`;

const CustomStepConnector = styled(StepConnector)`
  & .MuiStepConnector-line {
    border-color: rgba(255, 255, 255, 0.12);
    border-width: 2px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 1px;
  }
`;

const SendIcon = styled(SendIconSvg)`
  width: 22px;
  height: auto;
`;

const CreationIcon = styled(ChatCreationIconSvg)`
  width: 32px;
  height: auto;
`;

const StyleIcon = styled(ChatStyleIconSvg)`
  width: 32px;
  height: auto;
`;

const LibraryIcon = styled(ChatLibraryIconSvg)`
  width: 32px;
  height: auto;
`;

const ButtonText = styled(Box)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: var(--tg-safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const ChatBox = styled(Box)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  flex-grow: 1;
`;

const BottomBox = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0 0 18px 0;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px 24px 0px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0);
  background: linear-gradient(180deg, rgba(23, 25, 27, 0.01) 0%, #17191b 20%);
  box-shadow: 0px -8px 40px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
`;

const TopButtonBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  padding: 0 20px;
  margin: 16px 0;
  gap: 12px;
  width: 100%;
`;

const TopButton = styled(Box)`
  display: flex;
  height: 48px;
  padding: 8px 16px 8px 8px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 12px;
  border: 4px solid #2a2c2e;
  background: linear-gradient(
    94deg,
    rgba(60, 70, 70, 0.8) 4.44%,
    rgba(53, 60, 60, 0.7) 96.91%
  );
`;

const TextFieldBox = styled(Box)`
  display: flex;
  height: 51.072px;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: radial-gradient(
    81.39% 83.59% at 79.14% 43.6%,
    rgba(112, 234, 161, 0.12) 0%,
    rgba(255, 255, 255, 0.12) 100%
  );
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.2);
  margin: 0 20px;
`;

const CustomTextField = styled(TextField)`
  display: flex;
  & .MuiOutlinedInput-root {
    border-radius: 0;
    & fieldset {
      border: none;
    }
  }
  & .MuiInputBase-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1;
  }
  & .MuiInputBase-input {
    caret-color: #2be4d9;
    color: #ffffff;
  }
  flex-grow: 1;
`;

const SendButton = styled(Button)`
  display: flex;
  margin-left: 1px;
  min-width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: radial-gradient(
    79.76% 79.76% at 64.64% 35.45%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
`;

const StepTitle = styled(Typography)`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const StepDesc = styled(Typography)`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const CongratulationsTitle = styled(Typography)`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const CongratulationsDesc = styled(Typography)`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const StyleAvatar = styled('img')`
  width: 40px;
  height: 40px;
  border-radius: 10px;
`;

const GeneratingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 12px;
  background: rgba(78, 78, 78, 0.5);
  width: 92%;
  height: 92%;
  z-index: 9999;
`;

const GeneratingText = styled(Box)`
  color: var(--White-60, rgba(255, 255, 255, 0.6));
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const dotFlashing = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled(Typography)<{ delay: string }>`
  width: 3px;
  height: 3px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 50%;
  background-color: currentColor;
  animation: ${dotFlashing} 1s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay};
`;

const GuideIcon = styled(GuideIconSvg)`
  width: 40px;
  height: auto;
  position: absolute;
  left: 6vw;
  bottom: 1vh;

  animation: scaleAnimation 1s infinite ease-in-out;
  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const RecommendTitle = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 590;
  line-height: 28px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const RecommendTag = styled(Box)`
  display: flex;
  width: 100%;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: linear-gradient(
    94deg,
    rgba(60, 70, 70, 0.8) 4.44%,
    rgba(53, 60, 60, 0.7) 96.91%
  );

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const UploadContent = styled(Typography)`
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const steps = ['1', '2', '3'];

export interface MessageProps {
  type:
    | 'text'
    | 'tips'
    | 'image'
    | 'step2'
    | 'step3'
    | 'loading'
    | 'styleCard'
    | 'congratulations'
    | 'recommend'
    | 'uploadImage'
    | 'generating';
  content: string;
  image?: string;
  extraInfo?: string;
  sender: 'user' | 'bot';
}

interface ChatProps {
  fileStore: typeof fileStore;
}

interface RecommendStyleProps {
  styleId: string;
  styleName: string;
  styleImage?: string;
}

interface LocationState {
  selectedStyle?: StyleVo;
}

const Chat: React.FC<ChatProps> = ({ fileStore }) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { startLoading, stopLoading } = useToast();

  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [photoDrawerOpen, setPhotoDrawerOpen] = useState(false);
  const [isBottomVisible, setIsBottomVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [showGuideIcon, setShowGuideIcon] = useState(false);
  const [styleList, setStyleList] = useState<StyleVo[]>([]);
  const [photoList, setPhotoList] = useState<FileVo[]>([]);
  const [fileId, setFileId] = useState<string>('');
  const [selectedStyle, setSelectedStyle] = useState<StyleVo | null>(null);
  const [selectedPhoto, setSelectedPhoto] = useState<FileVo | null>(null);
  const [recommendStyleId, setRecommendStyleId] = useState<string>('');
  const [inputBottom, setInputBottom] = useState(0);
  const [isGenerator, setIsGenerator] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [selectedImageStr, setSelectedImageStr] = useState<string | null>(null);

  const scrollBottomRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const controllerRef = useRef<AbortController | null>(null);
  const soundRef = useRef<HTMLAudioElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScroll = () => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const scrollTop = scrollElement.scrollTop;
      if (scrollTop < lastScrollTop) {
        // setIsBottomVisible(false);
      } else if (scrollTop > lastScrollTop) {
        setIsBottomVisible(true);
      }
      setLastScrollTop(scrollTop);
    }
  };

  const handleStyleItem = (style: StyleVo) => {
    setSelectedStyle(style);
  };

  const handlePhotoItem = (photo: FileVo) => {
    setSelectedPhoto(photo);
    setFileId(photo?.fileId || '');
  };

  const handleAddButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastScrollTop]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const chatId = chatStore.getChatId();
    if (!chatId) {
      serverRequestApi.newChat().then((response) => {
        const newId = response.newChat || null;
        chatStore.setChatId(newId);
      });
    }

    const uploadedFile = fileStore.file;
    const isFirst = fileStore.isFirstVisit;
    const fileIdStr = fileStore.fileId;
    setFileId(fileIdStr || '');

    if (uploadedFile) {
      setIsGenerator(true);
    } else {
      setIsGenerator(false);
    }

    async function fetchStyleList(page = 1, pageSize = 10) {
      try {
        const response = await serverRequestApi.getStyleList({
          page,
          pageSize,
        });

        console.log('response', response);

        const newList = [
          ...(response.styles || []),
          {
            createdAt: '',
            createdBy: 0,
            enhancingFaceSim: 0,
            isPublic: 'false',
            likes: 0,
            loyaltyPoints: 0,
            name: 'MoreButton',
            tags: '',
            updatedAt: '',
            uuid: '',
          },
        ];

        if (location.state) {
          const stateStyle = location.state.selectedStyle;
          if (stateStyle) {
            const styleIndex = styleList.findIndex(
              (style) => style.uuid === stateStyle.uuid,
            );
            if (styleIndex > -1) {
              const updatedList = [...newList];
              const [style] = updatedList.splice(styleIndex, 1);
              updatedList.unshift(style);
              setStyleList(updatedList);
            } else {
              setStyleList([stateStyle, ...newList]);
            }
            setSelectedStyle(stateStyle);
            setDrawerOpen(true);

            if (location.pathname === '/chat') {
              history.replace({
                pathname: '/chat',
                state: null,
              });
            }
          }
        } else {
          setStyleList(newList);
        }
      } catch (error) {
        console.log('Error fetching style list:', error);
      }
    }

    fetchStyleList(1, 10);
    fetchPhotoList(1, 100);

    const handleWindowBlur = () =>
      scrollBottomRef.current?.scrollIntoView({ behavior: 'smooth' });

    window.addEventListener('focusout', handleWindowBlur);

    if (uploadedFile && isFirst) {
      console.log('Uploaded file:', uploadedFile);
      upload(uploadedFile);
      compressImage(uploadedFile).then((base64Str) => {
        const newMessage: MessageProps = {
          type: 'image',
          content: base64Str,
          sender: 'user',
        };
        setMessages(() => {
          const updatedMessages = [newMessage];

          const loadingMessage: MessageProps = {
            type: 'loading',
            content: 'Processing...',
            sender: 'bot',
          };

          return [...updatedMessages, loadingMessage];
        });
        setTimeout(() => {
          setMessages((prevMessages) => {
            const filteredMessages = prevMessages.filter(
              (msg) => msg.type !== 'loading',
            );

            const stepMessage: MessageProps = {
              type: 'step2',
              content: 'Step 2 completed!',
              sender: 'bot',
            };

            chatStore.setMessages([...filteredMessages, stepMessage]);
            return [...filteredMessages, stepMessage];
          });
          setDrawerOpen(true);
        }, 3000);
      });
      if (location.state && styleList && styleList.length > 0) {
        const stateStyle = location.state.selectedStyle;
        if (stateStyle) {
          console.log('stateStyle', stateStyle);
          setStyleList((prevList) => {
            const styleIndex = prevList.findIndex(
              (style) => style.uuid === stateStyle.uuid,
            );

            return styleIndex > -1
              ? [
                  prevList[styleIndex],
                  ...prevList.filter((_, i) => i !== styleIndex),
                ]
              : [stateStyle, ...prevList];
          });
          setSelectedStyle(stateStyle);
          setDrawerOpen(true);

          console.log('setDrawerOpen', true);
        }
      }
    } else {
      const messageList = chatStore.getMessages();
      if (messageList && messageList.length > 0) {
        setMessages(messageList);
      }
    }

    return () => {
      window.removeEventListener('focusout', handleWindowBlur);
    };
  }, []);

  useEffect(() => {
    controllerRef.current = new AbortController();

    const taskId = fileStore.getTaskId();
    if (taskId) {
      console.log('taskId', taskId);
      getSystemTaskResult(taskId);
    }

    const handleResize = () => {
      const newHeight = window.innerHeight;
      setInputBottom(
        newHeight < document.documentElement.clientHeight ? 400 : 0,
      );
    };

    window.addEventListener('resize', handleResize);

    return () => {
      controllerRef.current?.abort();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function fetchPhotoList(page = 1, pageSize = 10) {
    startLoading();
    try {
      const response = await serverRequestApi.getUserFiles({
        page,
        pageSize,
      });

      console.log('response', response);

      const newList = [
        ...(response.userFiles || []),
        {
          fileId: 'addButton',
          fileUrl: 'AddButton',
        },
      ];

      setPhotoList(newList);
      stopLoading();
    } catch (error) {
      console.log('Error fetching style list:', error);
      stopLoading();
    }
  }

  const compressImage = (
    file: File,
    quality = 0.7,
    maxWidth = 200,
    maxHeight = 400,
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            if (width > height) {
              height *= maxWidth / width;
              width = maxWidth;
            } else {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');

          if (ctx) {
            ctx.drawImage(img, 0, 0, width, height);
            const base64 = canvas.toDataURL('image/jpeg', quality);
            resolve(base64);
          } else {
            reject(new Error('无法获取 Canvas 上下文'));
          }
        };

        img.onerror = (error) => reject(error);
      };

      reader.onerror = (error) => reject(error);
    });
  };

  const getSystemTaskResult = (taskId: string) => {
    if (taskId && taskId !== '') {
      pollSystemTaskResult(taskId, 5000, controllerRef.current!)
        .then((result) => {
          console.log('Polling complete, task result:', result);

          fileStore.setTaskId(null);
          if (result?.status && result?.status === '1') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'generating',
              );

              const imageMessage: MessageProps = {
                type: 'image',
                content: result?.url || '',
                sender: 'bot',
              };

              return [...filteredMessages, imageMessage];
            });

            setTimeout(() => {
              const botReply: MessageProps = {
                type: 'congratulations',
                content: 'Congratulations!',
                sender: 'bot',
              };
              setMessages((prevMessages) => {
                chatStore.setMessages([...prevMessages, botReply]);
                return [...prevMessages, botReply];
              });
              setShowGuideIcon(true);
            }, 1000);
          } else {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) =>
                  msg.type !== 'step3' &&
                  msg.type !== 'generating' &&
                  msg.type !== 'tips',
              );

              const errorMessage: MessageProps = {
                type: 'text',
                content:
                  "Generate failed. The image does not meet the generation requirements. Please use a clearer image of a real person's facial area. Please try again.",
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, errorMessage]);
              return [...filteredMessages, errorMessage];
            });
          }
        })
        .catch((error) => {
          console.log('Error during polling:', error);
          setMessages((prevMessages) => {
            const filteredMessages = prevMessages.filter(
              (msg) =>
                msg.type !== 'step3' &&
                msg.type !== 'generating' &&
                msg.type !== 'tips',
            );

            const errorMessage: MessageProps = {
              type: 'text',
              content: 'Sorry! Something went wrong!',
              sender: 'bot',
            };

            chatStore.setMessages([...filteredMessages, errorMessage]);
            return [...filteredMessages, errorMessage];
          });
        });
    }
  };

  async function upload(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', '2');
    try {
      const response = await api.post('/uploadForGenerate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('response', response);
      fileStore.setIsFirstVisit(false);
      if (response.code === 200) {
        setFileId(response.data);
        fileStore.setFileId(response.data);
        return response.data;
      } else {
        setFileId('');
        showErrorToast(
          response?.msg ??
            'Sorry!Something went wrong!Please try again in a few minutes.',
        );
        return 'error';
      }
    } catch (error) {
      setFileId('');
      console.log('图片上传失败:', error);
      // showErrorToast(error);
      return 'error';
    }
  }

  const toggleDrawer = (open: boolean) => () => {
    setShowGuideIcon(false);
    setDrawerOpen(open);
  };

  const handleUserPhotosClose = () => {
    setPhotoDrawerOpen(false);
  };

  const handleGenerateBtnClick = () => {
    setPhotoDrawerOpen(false);
    setDrawerOpen(false);
    if (soundRef.current) {
      soundRef.current.play().catch((error) => {
        console.log('Error playing sound:', error);
      });
    }
    const newMessage: MessageProps = {
      type: 'styleCard',
      content: selectedStyle?.name || '',
      image: selectedStyle?.originalImagePath || '',
      sender: 'user',
    };
    if (recommendStyleId) {
      setMessages((prevMessages) => {
        chatStore.setMessages([...prevMessages]);

        const loadingMessage: MessageProps = {
          type: 'loading',
          content: 'Processing...',
          sender: 'bot',
        };

        return [...prevMessages, loadingMessage];
      });
    } else {
      setMessages((prevMessages) => {
        chatStore.setMessages([...prevMessages, newMessage]);

        const loadingMessage: MessageProps = {
          type: 'loading',
          content: 'Processing...',
          sender: 'bot',
        };

        return [...prevMessages, newMessage, loadingMessage];
      });
    }

    startLoading();
    const uploadedFile = fileStore.file;
    if (uploadedFile) {
      const uploadedFileUrl = URL.createObjectURL(uploadedFile);

      const generateImgReq = {
        styleId: selectedStyle?.uuid || '',
        fileId: fileId,
        isPublic: '0',
      };
      serverRequestApi
        .createImage({ generateImgReq })
        .then((response) => {
          const responseStr = response.createImage;
          if (responseStr === 'TASK_PENDING_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content: 'Please wait a while, your avatar is waiting in line.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else if (responseStr === 'TASK_RUNNING_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content:
                  'One of your avatar is generating. wait a minute to create another one.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else if (responseStr === 'TASK_QUEUE_FULL_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content:
                  'The pipeline is full is right now. Please try again later.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else if (responseStr === 'USER_CREATE_LIMIT_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content:
                  'You have exhausted your generation limit of 5 times for today.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else {
            console.log('responseStr', response);
            setTimeout(() => {
              setMessages((prevMessages) => {
                const filteredMessages = prevMessages.filter(
                  (msg) => msg.type !== 'loading',
                );

                const botReply: MessageProps = {
                  type: 'generating',
                  content: uploadedFileUrl,
                  sender: 'bot',
                };

                chatStore.setMessages([...filteredMessages, botReply]);
                return [...filteredMessages, botReply];
              });
            }, 1000);

            setTimeout(() => {
              const botReply: MessageProps = {
                type: 'step3',
                content: 'Step 3 completed!',
                sender: 'bot',
              };
              setMessages((prevMessages) => {
                chatStore.setMessages([...prevMessages, botReply]);
                return [...prevMessages, botReply];
              });
              setShowGuideIcon(true);
              fileStore.file = null;
            }, 3000);

            fileStore.setTaskId(responseStr || null);
            fileStore.setFile(null);
            fileStore.setFileId(null);
            getSystemTaskResult(responseStr || '');
            setSelectedStyle(null);
            setSelectedPhoto(null);
            stopLoading();
          }
          setIsGenerator(false);
        })
        .catch((error) => {
          console.log('-error-', error);
          stopLoading();
          setIsGenerator(false);
        });
    } else {
      const generateImgReq = {
        styleId: selectedStyle ? selectedStyle?.uuid || '' : recommendStyleId,
        fileId: fileId,
        isPublic: '0',
      };
      serverRequestApi
        .createImage({ generateImgReq })
        .then((response) => {
          const responseStr = response.createImage;
          if (responseStr === 'TASK_PENDING_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content: 'Please wait a while, your avatar is waiting in line.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else if (responseStr === 'TASK_RUNNING_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content:
                  'One of your avatar is generating. wait a minute to create another one.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else if (responseStr === 'TASK_QUEUE_FULL_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content:
                  'The pipeline is full is right now. Please try again later.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else if (responseStr === 'USER_CREATE_LIMIT_ACTION') {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const botReply: MessageProps = {
                type: 'text',
                content:
                  'You have exhausted your generation limit of 5 times for today.',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, botReply]);
              return [...filteredMessages, botReply];
            });
          } else {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const stepMessage: MessageProps = {
                type: 'generating',
                content: selectedPhoto?.fileUrl || '',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, stepMessage]);
              return [...filteredMessages, stepMessage];
            });

            setTimeout(() => {
              setMessages((prevMessages) => {
                const tipsMessage: MessageProps = {
                  type: 'tips',
                  content:
                    "You avatar is in progress! That is your Full NFT in Picwiz. It might take few minute in line. You could find it in 'My creation'.",
                  sender: 'bot',
                };

                chatStore.setMessages([...prevMessages, tipsMessage]);
                return [...prevMessages, tipsMessage];
              });
            }, 1000);

            fileStore.setTaskId(responseStr || null);
            fileStore.setFile(null);
            fileStore.setFileId(null);
            getSystemTaskResult(responseStr || '');
            setSelectedStyle(null);
            setSelectedPhoto(null);
          }
          stopLoading();
        })
        .catch((error) => {
          console.log('-error-', error);
          stopLoading();
        });
    }
  };

  const handleNextBtnClick = () => {
    setPhotoDrawerOpen(true);
    setDrawerOpen(false);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;

    const newMessage: MessageProps = {
      type: 'text',
      content: inputValue,
      sender: 'user',
    };
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];

      const loadingMessage: MessageProps = {
        type: 'loading',
        content: 'Processing...',
        sender: 'bot',
      };

      return [...updatedMessages, loadingMessage];
    });

    setInputValue('');

    const chatId = chatStore.getChatId();
    const chatReq = {
      chatId: chatId ?? '',
      query: inputValue,
    };
    serverRequestApi
      .chatMessages({ chatReq })
      .then((response) => {
        if (response && response?.chatMessages) {
          console.log('response', response);
          const { action, answer, extraInfo } = response?.chatMessages;
          if (action && action.length > 0) {
            if (action === 'ACTION_SELECT_STYLE') {
              const newMessage: MessageProps = {
                type: 'text',
                content: answer ?? 'Sorry,something error!',
                sender: 'bot',
              };
              setMessages((prevMessages) => {
                const filteredMessages = prevMessages.filter(
                  (msg) => msg.type !== 'loading',
                );

                return [...filteredMessages, newMessage];
              });
              setTimeout(() => {
                setMessages((prevMessages) => {
                  const stepMessage: MessageProps = {
                    type: 'recommend',
                    content: action,
                    extraInfo: extraInfo,
                    sender: 'bot',
                  };

                  chatStore.setMessages([...prevMessages, stepMessage]);
                  return [...prevMessages, stepMessage];
                });
              }, 1000);
            } else if (action === 'ACTION_UPLOAD_IMAGE') {
              setMessages((prevMessages) => {
                const filteredMessages = prevMessages.filter(
                  (msg) => msg.type !== 'loading',
                );

                const stepMessage: MessageProps = {
                  type: 'uploadImage',
                  content: action,
                  extraInfo: extraInfo,
                  sender: 'bot',
                };

                chatStore.setMessages([...filteredMessages, stepMessage]);
                return [...filteredMessages, stepMessage];
              });
            } else if (action === 'ACTION_RECOMMEND_STYLE') {
              setMessages((prevMessages) => {
                const filteredMessages = prevMessages.filter(
                  (msg) => msg.type !== 'loading',
                );

                const newMessage: MessageProps = {
                  type: 'text',
                  content:
                    answer ?? 'Let me recommend the following style for you.',
                  sender: 'bot',
                };

                chatStore.setMessages([...filteredMessages, newMessage]);
                return [...filteredMessages, newMessage];
              });
              setDrawerOpen(true);
            }
          } else {
            setMessages((prevMessages) => {
              const filteredMessages = prevMessages.filter(
                (msg) => msg.type !== 'loading',
              );

              const stepMessage: MessageProps = {
                type: 'text',
                content: answer ?? 'Sorry,something error!',
                sender: 'bot',
              };

              chatStore.setMessages([...filteredMessages, stepMessage]);
              return [...filteredMessages, stepMessage];
            });
          }
        }
      })
      .catch(() => {
        setMessages((prevMessages) => {
          const filteredMessages = prevMessages.filter(
            (msg) => msg.type !== 'loading',
          );

          const stepMessage: MessageProps = {
            type: 'text',
            content: 'Sorry,something error!',
            sender: 'bot',
          };

          chatStore.setMessages([...filteredMessages, stepMessage]);
          return [...filteredMessages, stepMessage];
        });
      });
  };

  const handleCreationBtnClick = () => {
    setShowGuideIcon(false);
    history.push('/myCreations');
  };

  const handleStyleBtnClick = () => {
    setDrawerOpen(true);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const uploadedFile = files[0];

      startLoading();
      upload(uploadedFile).then((fileStr) => {
        if (fileStr !== 'error') {
          fetchPhotoList(1, 100);
        }
        stopLoading();
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  async function pollSystemTaskResult(
    taskId: string,
    interval = 5000,
    controller = new AbortController(),
  ): Promise<SystemTaskVo> {
    const { signal } = controller;

    const checkStatus = async (
      resolve: (value?: any) => void,
      reject: (reason?: any) => void,
    ) => {
      try {
        if (signal.aborted) {
          reject(new Error('Polling was aborted'));
          return;
        }

        const response = await serverRequestApi.getSystemTaskResult({ taskId });

        if (
          response.systemTaskResult?.status === '0' ||
          response.systemTaskResult?.status === '1'
        ) {
          console.log(
            'Task completed successfully:',
            response.systemTaskResult,
          );
          resolve(response.systemTaskResult);
        } else {
          console.log('Task still in progress, retrying...');

          const timeoutId = setTimeout(() => {
            if (!signal.aborted) {
              checkStatus(resolve, reject);
            }
          }, interval);

          signal.addEventListener('abort', () => clearTimeout(timeoutId));
        }
      } catch (error) {
        if (signal.aborted) {
          console.log('Polling was aborted');
        } else {
          console.log('Error checking task status:', error);
          reject(error);
        }
      }
    };

    return new Promise(checkStatus);
  }

  const handleClose = () => {
    setImageOpen(false);
  };

  const renderMessageContent = (message: MessageProps) => {
    const messageStyle = {
      display: 'flex',
      padding: '12px 16px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      gap: '12px',
      borderRadius:
        message.sender === 'user' ? '16px 16px 0px 16px' : '16px 16px 16px 0px',
      border:
        message.sender === 'user'
          ? '1px solid rgba(74, 236, 129, 0.30)'
          : '1px solid rgba(255, 255, 255, 0.12)',
      background:
        message.sender === 'user'
          ? 'radial-gradient(119.29% 141.52% at 94.76% 119.23%, rgba(51, 227, 122, 0.60) 0%, rgba(132, 255, 181, 0.08) 100%)'
          : 'radial-gradient(81.39% 83.59% at 79.14% 43.6%, rgba(112, 234, 161, 0.12) 0%, rgba(255, 255, 255, 0.08) 100%)',
      boxShadow: '0px 4px 48px 0px rgba(0, 0, 0, 0.20)',
      backdropFilter: 'blur(6px)',
      maxWidth: '90%',
      color: '#FFF',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.40)',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '-0.442px',
      fontFamily: '"Bai Jamjuree", sans-serif',
    };
    switch (message.type) {
      case 'text':
        return <Box sx={messageStyle}>{message.content}</Box>;
      case 'tips':
        return <Box sx={messageStyle}>{message.content}</Box>;
      case 'image':
        return (
          <Box
            onClick={() => {
              setSelectedImageStr(message.content);
              setImageOpen(true);
            }}
            sx={{ ...messageStyle, padding: '8px' }}
          >
            <Box
              component="img"
              src={message.content}
              alt="image message"
              sx={{
                width: '160px',
                height: 'auto',
                minHeight: '160px',
                borderRadius: '12px',
                background:
                  'lightgray 50% / cover no-repeat url(<message.content>)',
              }}
            />
          </Box>
        );
      case 'step2':
        return (
          <Box sx={messageStyle}>
            <StepDesc>
              {
                "You look amazing! Let's gain some LazAI points with your new avatars."
              }
            </StepDesc>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <StepTitle>Step 2：</StepTitle>
              <StepTitle sx={{ color: '#53EB8D' }}>Select a style</StepTitle>
            </Box>
            <Stepper
              activeStep={1}
              alternativeLabel
              connector={<CustomStepConnector />}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {steps.map((label, idx) => (
                <Step key={label} sx={{ px: '30px' }}>
                  <StepLabel
                    StepIconComponent={({ active, completed }) => (
                      <CustomStepIcon active={active} completed={completed}>
                        {idx + 1}
                      </CustomStepIcon>
                    )}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        );
      case 'step3':
        return (
          <Box sx={messageStyle}>
            <StepDesc>
              {
                'Creating Your Full NFT Avatar... It might takes few minute in line. Keep exploring PicWiz while you wait.'
              }
            </StepDesc>
            <Box
              sx={{
                display: 'flex',
                marginTop: '12px',
                marginBottom: '12px',
                width: '100%',
              }}
            >
              <StepTitle>Step 3：</StepTitle>
              <StepTitle sx={{ color: '#53EB8D' }}>Exploring more</StepTitle>
            </Box>
            <Stepper
              activeStep={2}
              alternativeLabel
              connector={<CustomStepConnector />}
            >
              {steps.map((label, idx) => (
                <Step key={label} sx={{ px: '30px' }}>
                  <StepLabel
                    StepIconComponent={({ active, completed }) => (
                      <CustomStepIcon active={active} completed={completed}>
                        {idx + 1}
                      </CustomStepIcon>
                    )}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        );
      case 'loading':
        return (
          <Box sx={messageStyle}>
            <Box
              className="loading-spinner"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box className="loading-spinner-inner">
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <Box key={index} className="loading-spinner-circle"></Box>
                  ))}
              </Box>
            </Box>
          </Box>
        );
      case 'styleCard':
        return (
          <Box
            sx={{
              ...messageStyle,
              flexDirection: 'row',
              padding: '6px 12px 6px 6px',
            }}
          >
            <StyleAvatar src={message.image} /> OK, I wanna {message.content}{' '}
          </Box>
        );
      case 'generating':
        return (
          <Box sx={{ ...messageStyle, padding: '11px' }}>
            <Box
              component="img"
              src={message.content}
              alt="image message"
              sx={{
                width: '160px',
                height: 'auto',
                minHeight: '160px',
                borderRadius: '12px',
                background:
                  'lightgray 50% / cover no-repeat url(<message.content>)',
                filter: 'blur(6px)',
                overflow: 'hidden',
                position: 'relative',
              }}
            />
            <GeneratingBox>
              <HoneycombLoader />
              <GeneratingText>
                Generating
                <LoadingContainer>
                  <Dot delay="0s" />
                  <Dot delay="0.2s" />
                  <Dot delay="0.4s" />
                </LoadingContainer>
              </GeneratingText>
            </GeneratingBox>
          </Box>
        );
      case 'congratulations':
        return (
          <Box sx={{ ...messageStyle, alignItems: 'flex-start' }}>
            <CongratulationsTitle>🎉Congratulations!</CongratulationsTitle>
            <CongratulationsDesc>
              Your Full NFT Avatar has been generated!
            </CongratulationsDesc>
          </Box>
        );
      case 'recommend':
        const styles = JSON.parse(message.extraInfo || '[]');
        console.log('styles', styles);
        return (
          <Box sx={{ ...messageStyle }}>
            <RecommendTitle>Recommend style</RecommendTitle>
            {styles.map((item: RecommendStyleProps) => (
              <RecommendTag
                key={item.styleId}
                onClick={() => {
                  setRecommendStyleId(item.styleId);
                  setPhotoDrawerOpen(true);
                }}
              >
                {item.styleName}
              </RecommendTag>
            ))}
          </Box>
        );
      case 'uploadImage':
        const styleList = JSON.parse(message.extraInfo || '[]');
        const style =
          styleList && styleList.length > 0
            ? styleList[0]
            : ({} as RecommendStyleProps);
        console.log('style', style);
        return (
          <Box sx={{ ...messageStyle }}>
            <UploadContent>
              Please select a uploaded image from your library which to generate
              a avatar based on it
            </UploadContent>
            <MainButton
              text="Select image"
              height="40px"
              onClick={() => {
                setRecommendStyleId(style.styleId);
                setPhotoDrawerOpen(true);
              }}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const variants = {
    hidden: {
      y: '80px',
      Height: 0,
      opacity: 0,
    },
    visible: {
      y: 0,
      Height: '80px',
      opacity: 1,
    },
  };

  const handleBlur = () => {
    setInputBottom(0);
    scrollBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      inputRef.current?.blur();
      handleSendMessage();
    }
  };

  const scrollToInput = () => {
    setInputBottom(400);
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <ContainerBox
      ref={scrollBottomRef}
      sx={{
        paddingBottom: inputBottom,
        transition: 'padding-bottom 0.3s ease',
      }}
    >
      <Back />
      <audio ref={soundRef} src="/audio/textShow.mp3" preload="auto" />

      <ChatBox
        ref={scrollRef}
        onClick={() => {
          setIsBottomVisible(true);
        }}
      >
        <List sx={{ flexGrow: 1 }}>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              sx={{
                display: 'flex',
                justifyContent:
                  message.sender === 'user' ? 'flex-end' : 'flex-start',
              }}
            >
              {renderMessageContent(message)}
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </ChatBox>

      <BottomDrawer
        open={drawerOpen}
        isGenerator={isGenerator}
        styleList={styleList}
        selectedStyle={selectedStyle}
        onClose={toggleDrawer(false)}
        handleNextBtnClick={handleNextBtnClick}
        handleGenerateBtnClick={handleGenerateBtnClick}
        handleStyleItem={handleStyleItem}
      />

      <UserPhotos
        open={photoDrawerOpen}
        photoList={photoList}
        selectedFile={selectedPhoto}
        onClose={handleUserPhotosClose}
        handleGenerateBtnClick={handleGenerateBtnClick}
        handlePhotoItem={handlePhotoItem}
        handleAddButtonClick={handleAddButtonClick}
      />

      <AnimatePresence>
        {isBottomVisible && (
          <motion.div
            variants={variants}
            initial={isBottomVisible ? 'hidden' : 'visible'}
            animate={isBottomVisible ? 'visible' : 'hidden'}
            transition={{ duration: 0.3 }}
            style={{ width: '100%' }}
          >
            <BottomBox>
              <TopButtonBox>
                <TopButton onClick={handleCreationBtnClick}>
                  <CreationIcon />
                  <ButtonText>My Creations</ButtonText>
                </TopButton>
                <TopButton onClick={handleStyleBtnClick}>
                  <StyleIcon />
                  <ButtonText>Create a avatar</ButtonText>
                </TopButton>
                <TopButton
                  onClick={() => {
                    history.push('/styleLibrary');
                  }}
                >
                  <LibraryIcon />
                  <ButtonText>Style Library</ButtonText>
                </TopButton>
              </TopButtonBox>
              <TextFieldBox>
                <CustomTextField
                  inputRef={inputRef}
                  variant="outlined"
                  placeholder="Ask me anything"
                  value={inputValue}
                  onBlur={handleBlur}
                  onFocus={scrollToInput}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <SendButton onClick={handleSendMessage}>
                  <SendIcon />
                </SendButton>
              </TextFieldBox>
            </BottomBox>
          </motion.div>
        )}
      </AnimatePresence>
      <BottomNav onClick={() => {}} />

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {showGuideIcon && <GuideIcon />}
      <ImageDialog
        imageOpen={imageOpen}
        imageSrc={selectedImageStr}
        onClose={handleClose}
      />
    </ContainerBox>
  );
};

export default observer(Chat);
