import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, List, ListItem } from '@mui/material';
import styled from 'styled-components';
import {
  IntegrationIconSvg,
  Top1StarSvg,
  Top2StarSvg,
  Top3StarSvg,
} from 'assets/img';
import { serverRequestApi } from 'gql';
import { RankVo } from 'gql/server/__generated__/request';
import MainButton from 'components/MainButton';
import { motion } from 'framer-motion';
import { useToast } from 'components/ToastContext';
import WebApp from '@twa-dev/sdk';

const ContainerBox = styled(Box)`
  flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  padding: 20px;
  z-index: 99999;
`;

const JoinInBox = styled(Box)`
  display: flex;
  height: 68px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 20px;
  background: var(
    --Subtle-box-bg,
    radial-gradient(
      150.2% 50% at 50% 50%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.1) 100%
    )
  );
  box-shadow: -0.5px 0.5px 2px 0px rgba(144, 159, 148, 0.55) inset;
  backdrop-filter: blur(6px);
  margin-bottom: 20px;
`;

const JoinInText = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 16px 0 16px;
`;

const Title = styled(Typography)`
  color: #8a90a8;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 590;
  line-height: 20px;
  text-transform: uppercase;
`;

const LeaderboardList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const RankingItem = styled(ListItem)`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
`;

const RankingBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 18vw;
  height: 32px;
`;

const RankingLabel = styled(Box)`
  display: flex;
  height: 22px;
  min-width: 32px;
  padding: var(--Space-0, 0px) 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(
    --Bright-box,
    linear-gradient(
      95deg,
      rgba(255, 255, 255, 0.24) 3.83%,
      rgba(255, 255, 255, 0.3) 36.66%,
      rgba(255, 255, 255, 0.24) 96.41%
    )
  );
  color: #fff;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const NameLabel = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  line-height: 20px;
  width: 30vw;
`;

const PointBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 24vw;
`;

const PointText = styled(Typography)`
  color: #fff;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const MyRankingItem = styled(ListItem)`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
  background: var(
    --Subtle-box-bg,
    radial-gradient(
      150.2% 50% at 50% 50%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.1) 100%
    )
  );
  box-shadow: -0.5px 0.5px 2px 0px rgba(144, 159, 148, 0.55) inset;
  backdrop-filter: blur(6px);
`;

const MyRanking = styled(Box)`
  display: flex;
  height: 22px;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--Bubble-border, #638070);
  background: var(
    --Bubble-highlight,
    radial-gradient(179.22% 92.71% at 50% 7.29%, #274446 0%, #546962 100%)
  );
  box-shadow: -2px 4px 17.9px 0px rgba(81, 231, 150, 0.35) inset;
  color: #fff;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const IntegrationIcon = styled(IntegrationIconSvg)`
  width: 30px;
  height: auto;
  margin-right: 4px;
`;

const TopStar = styled('img')`
  width: 32px;
  height: auto;
`;

const Leaderboard: React.FC = () => {
  const [rankList, setRankList] = useState<RankVo[]>([]);
  const [myRank, setMyRank] = useState<RankVo>({});

  const { startLoading, stopLoading } = useToast();

  useEffect(() => {
    async function fetchTopRanks() {
      startLoading();
      try {
        const response = await serverRequestApi.getTopRanks();

        setRankList(response.topRanks || []);

        stopLoading();
      } catch (error) {
        console.log('Error fetching top ranks:', error);
      } finally {
        stopLoading();
      }
    }

    async function fetchMyRank() {
      try {
        const response = await serverRequestApi.getMyRank();

        setMyRank(response.myRank || {});
      } catch (error) {
        console.log('Error fetching top ranks:', error);
      }
    }

    fetchTopRanks();
    fetchMyRank();
  }, []);

  const creatTopNum = (ranking: number) => {
    switch (ranking) {
      case 1:
        return <TopStar src={require('assets/img/top-1-star.png')} />;

      case 2:
        return <TopStar src={require('assets/img/top-2-star.png')} />;

      case 3:
        return <TopStar src={require('assets/img/top-3-star.png')} />;

      default:
        return <RankingLabel>{ranking}</RankingLabel>;
    }
  };

  const variants = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.5,
      },
    }),
  };

  return (
    <ContainerBox>
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={0}
        style={{ width: '100%' }}
      >
        <JoinInBox>
          <JoinInText>Join LazAI Community and Earn</JoinInText>
          <MainButton
            text="Join"
            width="88px"
            height="38px"
            borderRadius="12px"
            onClick={() => {
              WebApp.openTelegramLink('https://t.me/lazai_global');
            }}
          />
        </JoinInBox>
      </motion.div>

      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={0.5}
        style={{ width: '100%' }}
      >
        <TitleBox>
          <Title sx={{ width: '18vw' }}>Ranking</Title>
          <Title sx={{ width: '30vw' }}>Name</Title>
          <Title sx={{ width: '24vw' }}>Point</Title>
        </TitleBox>
      </motion.div>

      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={1}
        style={{ width: '100%' }}
      >
        <LeaderboardList>
          <MyRankingItem>
            <RankingBox>
              <MyRanking>{myRank.rank ?? '-'}</MyRanking>
            </RankingBox>
            <NameLabel>Me</NameLabel>
            <PointBox>
              <IntegrationIcon />
              <PointText>{myRank.points ?? 0}</PointText>
            </PointBox>
          </MyRankingItem>
          {rankList.map((item) => (
            <RankingItem key={item.userId}>
              <RankingBox>{creatTopNum(item.rank ?? 0)}</RankingBox>
              <NameLabel>{item.username}</NameLabel>

              <PointBox>
                <IntegrationIcon />
                <PointText>{item.points}</PointText>
              </PointBox>
            </RankingItem>
          ))}
        </LeaderboardList>
      </motion.div>
    </ContainerBox>
  );
};

export default Leaderboard;
