import React, { useState, useEffect } from 'react';
import { Container, Box, Tabs, Tab, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MyStyleCard from 'components/MyStyleCard';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Back from 'components/Back';
import { serverRequestApi } from 'gql';
import { StyleImageVo } from 'gql/server/__generated__/request';
import HoneycombLoader from 'components/HoneycombLoader';
import { useToast } from 'components/ToastContext';
import { ManagePhotosSvg, DeleteOutlineSvg } from 'assets/img';
import ImageDialog from 'components/ImageDialog';

const StyleImage = styled('img')`
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
`;

const ContainerBox = styled(Box)`
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: calc(
    var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top)
  );
  padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const TitleLabel = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 28px;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const TitleTag = styled(Typography)`
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--white-pale, linear-gradient(90deg, #fff 0%, #e3ffe6 100%));
  color: #162a69;
  text-align: center;
  font-size: 14px;
  font-weight: 860;
  line-height: 20px;
  font-family: 'Bai Jamjuree', sans-serif;
  opacity: 1;
`;

const ManageBox = styled(Box)`
  display: flex;
  padding: 8px 20px 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: radial-gradient(
    81.39% 83.59% at 79.14% 43.6%,
    rgba(112, 234, 161, 0.12) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  margin-bottom: 20px;
`;

const ManagePhotos = styled(ManagePhotosSvg)`
  width: 38px;
  height: auto;
`;

const ManageContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

const ManageTitle = styled(Typography)`
  color: #53eb8d;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const ManageDesc = styled(Typography)`
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

const PhotoBox = styled(Box)`
  display: flex;
  padding: 13px 12px 14px var(--Space-0, 0px);
  align-items: center;
  gap: 12px;
`;

const PhotoTitle = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const Photo = styled('img')`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 14px;
  background: url(<path-to-image>) lightgray 50% / cover no-repeat;
`;

const AvatarsBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
`;

const AvatarItem = styled(Box)`
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const LikeBox = styled(Box)`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  align-items: center;
`;

const LikeLabel = styled(Typography)`
  color: white;
  margin-left: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const PublicBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 16px;
`;

const GreenPoint = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #70f06b;
  margin-right: 4px;
`;

const PublicLabel = styled(Typography)`
  color: #fff;
  font-size: 14px;
  font-weight: 590;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const StylesBox = styled(Box)`
  flex-grow: 1;
  width: 100%;
  height: calc(100% - 164px);
  overflow-y: auto;
`;

const CreateButton = styled(Button)`
  color: #53eb8d;
  text-transform: none;
  display: flex;
  height: 60px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: radial-gradient(
    81.39% 83.59% at 79.14% 43.6%,
    rgba(112, 234, 161, 0.12) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  font-family: 'Bai Jamjuree', sans-serif;
`;

const GeneratingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(78, 78, 78, 0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
`;

const GeneratingText = styled(Box)`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Bai Jamjuree', sans-serif;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const dotFlashing = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled(Typography)<{ delay: string }>`
  width: 3px;
  height: 3px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: currentColor;
  animation: ${dotFlashing} 1s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay};
`;

const EmptyBox = styled(Typography)`
  flex-grow: 1;
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyMessage = styled(Typography)`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.442px;
  width: 80%;
`;

const DeleteBox = styled(Box)`
  display: flex;
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 40px;
  width: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const DeleteIcon = styled(DeleteOutlineSvg)`
  width: 24px;
  height: auto;
`;

const styles = [
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: true,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: false,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: true,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: false,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: true,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: false,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: true,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: true,
  },
  {
    image: 'avatar1.png',
    styleName: 'KPOP girl style',
    likes: 9191,
    tags: ['POP star', 'Jewelry', '1 girl'],
    isPublic: true,
  },
];

const MyCreations: React.FC = () => {
  const history = useHistory();
  const { startLoading, stopLoading } = useToast();

  const [selectedTab, setSelectedTab] = useState(0);
  const [imageList, setImageList] = useState<StyleImageVo[]>([]);

  const [imageOpen, setImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<StyleImageVo | null>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    fetchMyStyleImages(1, 100);
  }, []);

  async function fetchMyStyleImages(page = 1, pageSize = 10) {
    startLoading();
    try {
      const response = await serverRequestApi.getMyStyleImages({
        page,
        pageSize,
      });

      console.log('Response:', response);
      setImageList(response.myStyleImages || []);
      stopLoading();
    } catch (error) {
      console.log('Error fetching style images:', error);
      stopLoading();
    }
  }

  const handleAvatarClick = (image: StyleImageVo) => {
    if (image?.generatedImagePath && image?.generatedImagePath.length > 0) {
      // history.push('/avatarDetail', { selectedImage: image });
      setSelectedImage(image);
      setImageOpen(true);
    }
  };

  const handleClose = () => {
    setImageOpen(false);
  };

  const handleDeleteImage = (image: StyleImageVo) => {
    startLoading();
    try {
      serverRequestApi
        .deleteMyImage({
          uuid: image.uuid || '',
        })
        .then(() => {
          fetchMyStyleImages(1, 100);
          stopLoading();
        });
    } catch (error) {
      console.log('Error fetching style images:', error);
      stopLoading();
    }
  };

  return (
    <ContainerBox>
      <Back />
      <TitleBox>
        <TitleLabel>My Creations</TitleLabel>
        <TitleTag>Full NFT</TitleTag>
      </TitleBox>

      <ManageBox
        onClick={() => {
          history.push('./myPhotos');
        }}
      >
        <ManagePhotos />
        <ManageContentBox>
          <ManageTitle>Manage my uploaded photos </ManageTitle>
          <ManageDesc>
            Your avatars will be created from your uploaded photos
          </ManageDesc>
        </ManageContentBox>
      </ManageBox>

      {/* <PhotoBox>
        <PhotoTitle>Created based on </PhotoTitle>
        <Photo />
      </PhotoBox> */}

      {selectedTab === 0 &&
        (imageList.length > 0 ? (
          <AvatarsBox>
            {imageList.map((image) => (
              <AvatarItem
                key={image.uuid}
                onClick={() => handleAvatarClick(image)}
              >
                <StyleImage
                  src={
                    image.status === '1'
                      ? image.generatedImagePath
                      : image.originalImagePath
                  }
                  style={{
                    background:
                      image.status === '1'
                        ? 'none'
                        : 'lightgray 50% / cover no-repeat url(<message.content>)',
                    filter: image.status === '1' ? 'none' : 'blur(6px)',
                  }}
                />
                {image.status === '1' ? (
                  <LikeBox>
                    <FavoriteIcon sx={{ color: '#FF4438' }} />
                    <LikeLabel>{image.likes}</LikeLabel>
                  </LikeBox>
                ) : image.status === '0' ? (
                  <GeneratingBox>
                    <GeneratingText>Failed</GeneratingText>
                  </GeneratingBox>
                ) : (
                  <GeneratingBox>
                    <HoneycombLoader />
                    <GeneratingText>
                      Generating
                      <LoadingContainer>
                        <Dot delay="0s" />
                        <Dot delay="0.2s" />
                        <Dot delay="0.4s" />
                      </LoadingContainer>
                    </GeneratingText>
                  </GeneratingBox>
                )}
                {image.isPublic === '1' && (
                  <PublicBox>
                    <GreenPoint />
                    <PublicLabel>PUBLIC</PublicLabel>
                  </PublicBox>
                )}
                <DeleteBox
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteImage(image);
                  }}
                >
                  <DeleteIcon />
                </DeleteBox>
              </AvatarItem>
            ))}
          </AvatarsBox>
        ) : (
          <EmptyBox>
            <EmptyMessage>
              {"You haven't generated a Full NFT yet"}
            </EmptyMessage>
            <EmptyMessage>{'chat with LazAI agent to try one'}</EmptyMessage>
          </EmptyBox>
        ))}

      {selectedTab === 1 && (
        <StylesBox>
          <CreateButton startIcon={<AddIcon />}>Create new style</CreateButton>
          {styles.map((style, index) => (
            <MyStyleCard
              key={index}
              image={style.image}
              styleName={style.styleName}
              likes={style.likes}
              tags={style.tags}
              isPublic={style.isPublic}
            />
          ))}
        </StylesBox>
      )}
      <ImageDialog
        imageOpen={imageOpen}
        selectedImage={selectedImage}
        onClose={handleClose}
      />
    </ContainerBox>
  );
};

export default MyCreations;
